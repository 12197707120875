import { createContext } from "react"
import { noop } from "../../core/utils"
import { TopicList, TopicMap } from "./topic-types"

export interface TopicsContext {
  topicLists: TopicList[]
  topicMap: TopicMap
  setTopicLists: (topicLists: TopicList[]) => void
  setTopicMap: (topicMap: TopicMap) => void
}

export const topicsContextDefaultValue: TopicsContext = {
  topicLists: [],
  topicMap: {},
  setTopicLists: noop,
  setTopicMap: noop
}

export const TopicsContext = createContext<TopicsContext>(
  topicsContextDefaultValue
)
