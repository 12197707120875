import cloneDeep from "lodash/cloneDeep"
import { v4 as uuidv4 } from "uuid"
import { StorageCart, StorageCartItem } from "./store-types"

const updatePeriod = 5 // minutes
const minuteMilliseconds = 60 * 1000 // milisec
const domain = process.env.GATSBY_STOREFRONT_DOMAIN || "store.ligonier.org"
const storageCartKey = "lig-shopify-cart"

export const saveCart = (cart: StorageCart): void => {
  sessionStorage.setItem(storageCartKey, JSON.stringify(cart))
}

export const createCart = (): StorageCart => {
  const cart: StorageCart = {
    id: uuidv4(),
    items: [],
    nextUpdateTimestamp: null
  }

  saveCart(cart)

  return cart
}

export const getStoredCart = (): StorageCart => {
  const cart = sessionStorage.getItem(storageCartKey)

  if (cart === null) {
    return createCart()
  }

  return JSON.parse(cart) as StorageCart
}

export const addItemToCart = (
  cart: StorageCart,
  itemId: string
): StorageCart => {
  const newCart: StorageCart = cloneDeep(cart)
  const currentItemIndex = newCart.items.findIndex((item) => item.id === itemId)

  if (currentItemIndex !== -1) {
    newCart.items[currentItemIndex].quantity += 1
  } else {
    newCart.items = [
      ...newCart.items,
      {
        id: itemId,
        quantity: 1
      }
    ]
  }

  return newCart
}

export const setCartItems = (
  cart: StorageCart,
  items: StorageCartItem[]
): StorageCart => {
  const newCart: StorageCart = cloneDeep(cart)

  newCart.items = [...items]

  return newCart
}

export const getCartUrl = (cart: StorageCart | null): string | undefined => {
  if (!cart || cart.items.length === 0) {
    return `https://${domain}/cart`
  }

  const urlTokens = cart.items.map(
    (item) => `updates[${item.id}]=${item.quantity}`
  )

  return `https://${domain}/cart/update?${urlTokens.join("&")}`
}

export const setNextUpdateTime = (
  cart: StorageCart,
  minutes: number
): StorageCart => {
  const newCart: StorageCart = cloneDeep(cart)

  newCart.nextUpdateTimestamp = Date.now() + minutes * minuteMilliseconds

  return newCart
}

export const shouldCartUpdate = (cart: StorageCart): boolean => {
  const ts = cart.nextUpdateTimestamp

  return ts !== null && Date.now() > ts
}

export const getCartItemCount = (cart: StorageCart | null): number => {
  if (!cart) {
    return 0
  }

  return cart.items.reduce<number>((sum, item) => sum + item.quantity, 0)
}

interface CartResponse {
  items: StorageCartItem[]
}

export const updateCart = (callback: (cart: StorageCart) => void): void => {
  window.shopifyCartResponse = function (data: CartResponse) {
    const cart = getStoredCart()

    const items = (data.items || []).map((item) => ({
      id: item.id,
      quantity: item.quantity
    }))
    let newCart = setCartItems(cart, items)

    newCart = setNextUpdateTime(newCart, updatePeriod)

    callback(newCart)
  }

  const script = document.createElement("script")

  script.src = `https://${domain}/cart.json?callback=shopifyCartResponse`
  document.getElementsByTagName("head")[0].appendChild(script)
}
