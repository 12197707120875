import { Metadata } from "../types/metadata"

export const externalLink = {
  rel: "noopener noreferrer",
  target: "_blank"
}

export const defaultSynopsisMessage =
  "This sermon is from the Lord’s Day pulpit ministry of R.C. Sproul at Saint Andrew’s Chapel near Orlando, Florida."

export const defaultMetadata: Metadata = {
  lang: "en-US",
  title: "Trustworthy Bible Teaching and Discipleship Resources",
  useTitleTemplate: true,
  description: [
    "Ligonier Ministries, founded by R.C. Sproul, exists",
    "to proclaim, teach, and defend the holiness of God",
    "in all its fullness to as many people as possible."
  ].join(" "),
  keywords: [
    "Reformed",
    "Reformed Theology",
    "Reformation",
    "Bible",
    "Theology",
    "Bible Study",
    "Apologetics",
    "Worldview",
    "Culture",
    "Christian Living",
    "Church History",
    "R.C. Sproul",
    "Sinclair Ferguson",
    "Stephen Nichols",
    "Burk Parsons"
  ],
  openGraphImage: "https://www.ligonier.org/assets/og.png",
  openGraphSiteName: "Ligonier Ministries",
  openGraphType: "website",
  twitterCard: "summary_large_image",
  twitterSite: "@Ligonier"
}

export const defaultTitleTemplate = "{title} – Ligonier Ministries"

export const searchPlaceholder = "Search for Products, Topics, and Teachers"

export const wrongEmailErrorMessage = "Temporary wrong email error message"

export const requiredFieldMessage = "This field is required"

export const defaultWordsPerMinute = 265 // needs to be consistent with gatsby-transformer-remark plugin

export const defaultTruncationLength = 160

export const minReadingTime = 1

export enum Keys {
  Down = "ArrowDown",
  Enter = "Enter",
  Up = "ArrowUp"
}

export enum ArticleType {
  Generic = "Generic Article",
  Tabletalk = "Tabletalk Article"
}

export enum PageOpenGraphImage {
  CoramDeo = "https://images.ctfassets.net/ukqj0ybhazqg/w0yQpjmsFlX7RpNLJjXXs/38bee7d7fc0a7fce21cf773f01861a93/1200x630_eBlastHero_CoramDeo.jpg",
  Learn = "https://images.ctfassets.net/ukqj0ybhazqg/nk2cmTpci4q4ygk0jXcYm/7849b21ecef2fbf35b70f550afad3895/3840x2160_WaysToLearn_12_Hero.jpg",
  Series = "https://images.ctfassets.net/ukqj0ybhazqg/6xTOqVaxISnst1cqsnWjNs/38560ae82d1e0ffc8fb29da75fb41ebd/3840x2160_WaysToLearn_01_TeachingSeries.jpg",
  Conferences = "https://images.ctfassets.net/ukqj0ybhazqg/bXu0IT738Zud5Uvg7Hicd/2321d2a5cfffadcbaeb8db5a8773b252/3840x2160_WaysToLearn_02_ConferenceMessages.jpg",
  Qas = "https://images.ctfassets.net/ukqj0ybhazqg/4rDjfbT6iTQ5TDqgCfd7n3/d641115d2134ec65640f2a228be53481/3840x2160_WaysToLearn_03_QuestionsAndAnswers.jpg",
  Devotionals = "https://images.ctfassets.net/ukqj0ybhazqg/tTaOoSSGEWwyTIumbSNM8/7b7ba215516d7b0ba9c85f4192cd8d32/3840x2160_WaysToLearn_04_Devotionals.jpg",
  Articles = "https://images.ctfassets.net/ukqj0ybhazqg/RALIzn7Jfq1i9Ho8gjdZ7/e9540f87143273d9aced62c08b8a59f9/3840x2160_WaysToLearn_05_Articles.jpg",
  Sermons = "https://images.ctfassets.net/ukqj0ybhazqg/2Kqe3hlUCWLVXzf6OTnM8v/e6ce79636f8c4ca1c38db75524480553/3840x2160_WaysToLearn_06_Sermons.jpg",
  Topics = "https://images.ctfassets.net/ukqj0ybhazqg/5LWiFflOzcky6ljPSD4Cl1/b3369b54be83d1a0c0f9d96c2271abb4/3840x2160_WaysToLearn_08_TopicIndex.jpg",
  Teachers = "https://images.ctfassets.net/ukqj0ybhazqg/JdqYLuHpzfuby3tTcpMCV/3ed9bc91a0dff43776dcc24e0f2016db/3840x2160_WaysToLearn_09_TeacherIndex.jpg",
  Scripture = "https://images.ctfassets.net/ukqj0ybhazqg/1XiBUeXG02wXeSVrS5Z2zi/33cdbd4b2c10dfcb2054409e1a6a6bf5/3840x2160_WaysToLearn_10_ScriptureIndex.jpg",
  Collections = "https://images.ctfassets.net/ukqj0ybhazqg/4tA3jqNoEgbNKJFIi1i7Cb/6f63c7b05c782b92cb7c11a199dc5431/3840x2160_WaysToLearn_11_ResourceCollections.jpg",
  Home = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/38fd676959ac7de2493db58ee8bcd60d/3840x2160_NLO_HomeText_Social.jpg",
  About = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/086f7e31073849ac5f23990c2cd9e9ae/3840x2160_NLO_About_Social.jpg",
  Give = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/29ea6e143433fca2248a378a3d6b6886/3840x2160_NLO_Give_Social.jpg",
  Donate = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/84e3cda7b0f1f3c610d9e8e346c1ee4f/3840x2160_NLO_Donate_Social.jpg",
  InternationalOutreach = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/2a1d3f89dae5928431a437111a333c70/3840x2160_NLO_InternationalOutreach_Social.jpg",
  InternationalOutreachArabic = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/f7aa000bd756f0c34aa7594254061b91/3840x2160_NLO_InternationalOutreach_Arabic_Social.jpg",
  MilitaryChaplainOutreach = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/0f1842697950190aac46b919b46af67c/3840x2160_NLO_MilitaryChaplainOutreach_Social.jpg",
  PrisonOutreach = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/c62e8bf53e1725f175bc2f0c85be2d6c/3840x2160_NLO_PrisonOutreach_Social.jpg",
  WaysToGive = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/506cd954fb9e130727f9ef04edb9e2f9/3840x2160_NLO_WaystoGive_Social.jpg",
  NonCashGiving = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/86e4f7e221b024e55f4627d9e79eb3cf/3840x2160_NLO_NonCash_Social.jpg",
  MinistryPartners = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/45fa2ed37fe5570cb1eb2db692d56480/3840x2160_NLO_MinistryPartners_Social.jpg",
  OutreachProjects = "https://images.ctfassets.net/ukqj0ybhazqg/2CETXa6afXeNAq5dWxP8Ph/9c6eb6492514ceb04c3e69687d8d9b93/3840x2160_NLO_OutreachProjects_Social.jpg"
}

export const filterOptionsHeadSize = 5

const STORE_ROOT_URL = process.env.GATSBY_STORE_ROOT_URL

export const Store = `${STORE_ROOT_URL}`
export const StoreCart = `${STORE_ROOT_URL}/cart`
export const StoreCollection = `${STORE_ROOT_URL}/collection`
export const StoreFormat = `${STORE_ROOT_URL}/format`
export const StoreType = `${STORE_ROOT_URL}/type`

// NOTE: `event` are being added in Contentful as the entire pathname & slug
// e.g "/events/2022-national-conference/"
// ...and this codebase knows nothing about the `/events/` pathname
// we should really change that so it acts like the others
// Contentful should only have slugs. The value of `Events` should be: "/events"
export enum PathPrefix {
  About = "/about",
  Articles = "/learn/articles",
  Collections = "/learn/collections",
  Conferences = "/learn/conferences",
  DailyVideo = "/learn/daily-video",
  Devotional = "/learn/devotionals",
  Events = "",
  FAQs = "/faqs",
  FinancialInformation = "/about/financial-information",
  Giving = "/ways-to-give",
  Guides = "/guides",
  Information = "",
  Outreach = "/outreach-projects",
  Learn = "/learn",
  MediaResource = "",
  News = "/posts",
  Podcasts = "/podcasts",
  PodcastsIndex = "/podcasts/index",
  Post = "/posts",
  QuestionsAnswered = "/learn/qas",
  Scriptures = "/learn/scripture",
  Search = "/search",
  Series = "/learn/series",
  Sermon = "/learn/sermons",
  Support = "/give",
  Teachers = "/learn/teachers",
  Topics = "/learn/topics",
  Video = "", // video is a special case - it's a part of a series so it doesn't have its separate url
  WhatWeBelieve = "/about/what-we-believe",
  WhoWeAre = "/about/who-we-are",
  SupportingLigonier = "/about/supporting-ligonier"
}

export const shopifyVariantPrefix = "Shopify__ProductVariant__"

export enum ImagePlaceholders {
  card = "/assets/image-placeholders/card.png"
}

export const defaultAvatarUrl =
  "https://images.ctfassets.net/ukqj0ybhazqg/profile-default.gif/30ca1227523b1b8bc2942677f9ab0945/Default_Teacher_Image_for_Contentful2.jpg?w=96&h=96&q=50"

export const donateUrl = process.env.GATSBY_DJANGO_REDIRECT_URL?.includes(
  "develop-django.ligonier.org"
)
  ? "https://develop-donate.ligonier.org"
  : process.env.GATSBY_DJANGO_REDIRECT_URL?.includes(
      "stage-django.ligonier.org"
    )
  ? "https://stage-donate.ligonier.org"
  : "https://donate.ligonier.org"

export const accountUrl = process.env.GATSBY_DJANGO_REDIRECT_URL?.includes(
  "develop-django.ligonier.org"
)
  ? "https://develop-account.ligonier.org"
  : process.env.GATSBY_DJANGO_REDIRECT_URL?.includes(
      "stage-django.ligonier.org"
    )
  ? "https://stage-account.ligonier.org"
  : "https://account.ligonier.org"

export const eventsUrl = process.env.GATSBY_DJANGO_REDIRECT_URL?.includes(
  "develop-django.ligonier.org"
)
  ? "https://develop-events.ligonier.org"
  : process.env.GATSBY_DJANGO_REDIRECT_URL?.includes(
      "stage-django.ligonier.org"
    )
  ? "https://stage-events.ligonier.org"
  : "https://events.ligonier.org"
