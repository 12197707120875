import { useQuery } from "react-query"
import { defaultHeaders } from "@utils/shopify-storefront"
import { User } from "src/machines/authMachine.machine"

type UserArg = User | null

type UserIDResponse = string

const ligonerUserUrl = process.env.GATSBY_LIGONIER_USER_API_URL

const fetchLigonierUser = async (user?: UserArg, idToken?: string) => {
  const userIdentityUrl = `${ligonerUserUrl}/api/LMUserIdentity`

  if (!user) {
    console.error("there is no user")

    return
  }

  if (!idToken) {
    console.error("there is no id token")

    return
  }

  if (!ligonerUserUrl) {
    console.error("there is no user API url")

    return
  }

  const userIDResponse = await fetch(userIdentityUrl, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${idToken}`
    },
    body: JSON.stringify({}),
    method: "POST",
    mode: "cors"
  })

  const userIdentity: UserIDResponse = await userIDResponse.json()

  if (!userIdentity) {
    console.error("no user Identity returned")

    return
  }

  user.userId = userIdentity

  return user
}

export function useLigonierUser(user?: UserArg, idToken?: string) {
  let queryKey = `ligonierUser`

  if (user) {
    queryKey += `: ${user.email}`
  }

  const {
    isLoading,
    error,
    data: userData
  } = useQuery([queryKey, user?.email, idToken], () =>
    fetchLigonierUser(user, idToken)
  )

  if (isLoading) {
    return
  }

  if (error) {
    console.error(error)

    return user
  }

  if (userData) {
    return userData
  }
}
