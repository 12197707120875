import { createClient } from "contentful"

const client = createClient({
  host: process.env.GATSBY_CONTENTFUL_HOST,
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID || "",
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN || "",
  environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT || "master"
})

export { client }
export default client
