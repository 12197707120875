import type { GatsbyBrowser } from "gatsby"

const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({ location }) => {
  if (
    location.pathname === "/templates/django" &&
    typeof window !== "undefined" &&
    window.pagePathDjango
  ) {
    window.history.replaceState({}, "", window.pagePathDjango)
    window.pagePathDjango = ""
  }
}

export default onPreRouteUpdate
