import { Alert } from "./alert-bar-component"

export function StoreInSessionStorage(item: Alert) {
  // check for prev session storage
  const sessionAlerts = sessionStorage.getItem("ligonierAlerts")

  // save alert in hashMap form to session storage so alerts
  // do not keep popping up on every page
  if (sessionAlerts) {
    // if session already exist just add onto the hashMap
    sessionStorage.setItem(
      "ligonierAlerts",
      JSON.stringify({
        ...JSON.parse(sessionAlerts),
        [item.id]: true
      })
    )
  } else {
    // otherwise start a new hashMap for alerts
    sessionStorage.setItem(
      "ligonierAlerts",
      JSON.stringify({ [item.id]: true })
    )
  }
}

export function StoreInLocalStorage(item: Alert) {
  // check for prev local storage
  const localNotifications = localStorage.getItem("ligonierNotifications")

  // save alert in hashMap form to local storage so alerts
  // do not keep popping up on every page
  if (localNotifications) {
    // if local already exist just add onto the hashMap
    localStorage.setItem(
      "ligonierNotifications",
      JSON.stringify({
        ...JSON.parse(localNotifications),
        [item.id]: true
      })
    )
  } else {
    // otherwise start a new hashMap for alerts
    localStorage.setItem(
      "ligonierNotifications",
      JSON.stringify({ [item.id]: true })
    )
  }
}
