import { useLayoutEffect } from "react"

export const useIE = (): void => {
  useLayoutEffect(() => {
    const isIE11 = "msCrypto" in window

    if (isIE11) {
      document.body.classList.add("ie11")
    }
  }, [])
}
