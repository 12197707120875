import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from "react"
import {
  CommerceCartProduct,
  CommerceCartProps
} from "../../core/segment/types"
import { useSegmentEvent } from "../../hooks/use-segment-event"
import { StoreContext } from "./store-context"
import {
  ProductAvailabilitySource,
  Availability
} from "./product-availability-source"
import { DynamicPrices, StorageCart } from "./store-types"
import { defaultPrices } from "./store-constants"
import {
  addItemToCart,
  getCartItemCount,
  getCartUrl,
  getStoredCart,
  saveCart,
  setNextUpdateTime,
  shouldCartUpdate,
  updateCart
} from "./store-cart"

export const StoreProvider: FunctionComponent = ({ children }) => {
  const [availabilitySource, setAvailabilitySource] =
    useState<ProductAvailabilitySource | null>(null)
  const [availability, setAvailability] = useState<
    Record<string, Availability>
  >({})
  const [shopingCart, setShopingCart] = useState<StorageCart | null>(null)
  const [prices, setPrices] = useState<Record<string, DynamicPrices>>({})
  const [location, setLocation] = useState<string | null>(null)

  const { triggerEvent } = useSegmentEvent()

  useEffect(() => {
    const cart = getStoredCart()

    if (shouldCartUpdate(cart)) {
      updateCart((newCart: StorageCart) => {
        saveCart(newCart)
        setShopingCart(newCart)
      })
    }
  }, [location, shopingCart])

  useEffect(() => {
    const cart = getStoredCart()

    if (shouldCartUpdate(cart)) {
      updateCart((newCart: StorageCart) => {
        saveCart(newCart)
        setShopingCart(newCart)
      })
    }

    setShopingCart(cart)
  }, [])

  useEffect(() => {
    setAvailabilitySource(
      new ProductAvailabilitySource(
        {
          storefrontAccessToken:
            process.env.GATSBY_STOREFRONT_ACCESS_TOKEN || "",
          storefrontDomain: process.env.GATSBY_STOREFRONT_DOMAIN || "",
          onFetchStart: (handle) => {
            setPrices((p) => ({ ...p, [handle]: defaultPrices }))
            setAvailability((a) => ({ ...a, [handle]: Availability.unknown }))
          },
          onFetchSuccess: (handle, result) => {
            setPrices((p) => ({
              ...p,
              [handle]: {
                price: result.price,
                compareAtPrice: result.compareAtPrice
              }
            }))
            setAvailability((a) => ({ ...a, [handle]: result.availability }))
          },
          onFetchFailure: (handle, err) => {
            console.log(`Could not fetch availability: ${err}`)
            setPrices((p) => ({ ...p, [handle]: defaultPrices }))
            setAvailability((a) => ({ ...a, [handle]: Availability.error }))
          }
        },
        fetch.bind(window)
      )
    )
  }, [])

  const addVariantToCart = async (
    ids: string[],
    variantDetails?: CommerceCartProduct[],
    category?: string
  ) => {
    if (shopingCart) {
      if (variantDetails) {
        const cartProps: CommerceCartProps = {
          cart_id: `${shopingCart.id}`,
          category,
          products: variantDetails
        }

        triggerEvent(cartProps, "Product Added", true)
      }

      let cart: StorageCart = shopingCart

      // IDs on products not obtained from Algolia will have "ProductVariant_" or similar prepended
      const idRegex = /^(\w+_)?gid:\/\/shopify\/ProductVariant\//

      ids.forEach((id) => {
        const variantId = id.replace(idRegex, "")

        cart = addItemToCart(cart, variantId)
      })

      cart.nextUpdateTimestamp = null // until the cart is updated on shopify site, we don't want to run automatic updates

      saveCart(cart)
      setShopingCart(cart)
    }
  }

  const setNextCartUpdateTime = useCallback(
    (minutes: number) => {
      if (shopingCart) {
        const newCart = setNextUpdateTime(shopingCart, minutes)

        saveCart(newCart)
      }
    },
    [shopingCart]
  )

  const updateLocation = useCallback((location: Location) => {
    setLocation(location.href)
  }, [])

  const value: StoreContext = {
    availabilitySource,
    availability,
    prices,
    checkoutUrl: getCartUrl(shopingCart),
    itemCount: getCartItemCount(shopingCart),
    addVariantToCart,
    setNextCartUpdateTime,
    updateLocation
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}
