import { gql } from "graphql-request"
import { LINE_ITEM_FRAGMENT } from "../fragments/LINE_ITEM_FRAGMENT"

export const ADD_LINE_ITEM = gql`
  mutation addLineItem($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        checkoutUrl
        id
        ...LineItemFragment
      }
      userErrors {
        message
      }
    }
  }
  ${LINE_ITEM_FRAGMENT}
`
