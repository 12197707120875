import { LogLevel } from "@azure/msal-browser"
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = typeof window !== "undefined" ? window.navigator.userAgent : ""
const msie = ua.indexOf("MSIE ")
const msie11 = ua.indexOf("Trident/")
const msedge = ua.indexOf("Edge/")
const firefox = ua.indexOf("Firefox")
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SIGNUPORSIGNIN"
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://login.ligonier.org/login.ligonier.org/B2C_1A_SIGNUPORSIGNIN"
    }
  },
  authorityDomain: "login.ligonier.org"
}

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.GATSBY_AZURE_CLIENT_ID || "",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: "/auth-callback",
    postLogoutRedirectUri: "/"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (
        level: number,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message)

            return
          case LogLevel.Info:
            console.info(message)

            return
          case LogLevel.Verbose:
            console.debug(message)

            return
          case LogLevel.Warning:
            console.warn(message)

            return
          default:
            return
        }
      }
    }
  }
}

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
  scopes: [
    "offline_access",
    "openid",
    "profile",
    //NOTE: We need to send the client ID in order to request an access token(https://learn.microsoft.com/en-us/azure/active-directory-b2c/access-tokens?WT.mc_id=Portal-Microsoft_AAD_B2CAdmin#openid-connect-scopes)
    process.env.GATSBY_AZURE_CLIENT_ID || ""
  ]
}

// Scopes you add here will be prompted for consent during login
export const tokenRequest = {
  scopes: [
    "offline_access",
    "openid",
    "profile",
    //NOTE: We need to send the client ID in order to request an access token(https://learn.microsoft.com/en-us/azure/active-directory-b2c/access-tokens?WT.mc_id=Portal-Microsoft_AAD_B2CAdmin#openid-connect-scopes)
    process.env.GATSBY_AZURE_CLIENT_ID || ""
  ]
}
