import { createMachine } from "@xstate/compiled"
import { assign } from "xstate"
import { ProductVariant } from "../features/store/store-types"
import { InvokeCallbackType } from "@src-types/machines"

export type Events =
  | { type: "PRODUCTS_FOUND"; data: Array<ProductVariant> }
  | { type: "PRODUCTS_NOT_FOUND" }
  | { type: "ADD_PRODUCTS"; products: Array<ProductVariant> }
  | { type: "PRODUCT_ADDED"; data: Array<ProductVariant> }
  | { type: "PRODUCT_EXIST" }

export interface Context {
  products: Array<ProductVariant>
}

export const RVP_KEY = "LIGONIER_PRODUCTS"

const recentlyViewedMachine = createMachine<
  Context,
  Events,
  "recentlyViewedMachine"
>(
  {
    id: "recentlyViewedMachine",
    initial: "checkingForProducts",
    context: {
      products: []
    },
    states: {
      checkingForProducts: {
        invoke: {
          src: "checkingForProducts"
        },
        on: {
          PRODUCTS_FOUND: {
            target: "idle",
            actions: ["saveProducts"]
          },
          PRODUCTS_NOT_FOUND: "idle"
        }
      },
      idle: {
        on: {
          ADD_PRODUCTS: "addingProducts"
        }
      },
      addingProducts: {
        invoke: {
          src: "addProducts"
        },
        on: {
          PRODUCT_ADDED: {
            target: "idle",
            actions: ["saveProducts"]
          },
          PRODUCT_EXIST: "idle"
        }
      }
    }
  },
  {
    services: {
      addProducts:
        (ctx: Context, event: { products: Array<ProductVariant> }) =>
        (cb: InvokeCallbackType) => {
          const found = ctx.products.some(
            (variant: ProductVariant) =>
              event.products.findIndex(
                ({ handle }) => handle === variant.handle
              ) >= 0
          )

          if (found) {
            cb("PRODUCT_EXIST")
          } else {
            cb({ type: "PRODUCT_ADDED", data: event.products })
          }
        },
      checkingForProducts: () => (cb: InvokeCallbackType) => {
        const products = sessionStorage.getItem(RVP_KEY)

        if (products) {
          cb({ type: "PRODUCTS_FOUND", data: JSON.parse(products) })
        } else {
          cb("PRODUCTS_NOT_FOUND")
        }
      }
    },
    actions: {
      saveProducts: assign((ctx, event) => {
        const products = [...event.data, ...ctx.products].slice(0, 8) // grab the most recent 8 products

        sessionStorage.setItem(RVP_KEY, JSON.stringify(products))

        return {
          products
        }
      })
    }
  }
)

export default recentlyViewedMachine
