import { gql } from "graphql-request"
import { LINE_ITEM_FRAGMENT } from "../fragments/LINE_ITEM_FRAGMENT"

export const CREATE_CART = gql`
  mutation cartCreate {
    cartCreate {
      cart {
        id
        checkoutUrl
        ...LineItemFragment
      }
      userErrors {
        message
      }
    }
  }
  ${LINE_ITEM_FRAGMENT}
`
