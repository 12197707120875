import React from "react"
import { withLDProvider } from "launchdarkly-react-client-sdk"

const clientSideID = process.env.GATSBY_LAUNCHDARKLY_CLIENTSIDE_ID || ""

if (!clientSideID) {
  console.warn(
    "No Launch Darkly Client Key set. Running site without feature flags"
  )
}

const Wrapper: React.FunctionComponent = ({ children }) => <>{children}</>

export const FeatureFlagProvider = withLDProvider({ clientSideID })(Wrapper)
