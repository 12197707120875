import { createContext } from "react"
import { CommerceCartProduct } from "../../core/segment/types"
import { noop } from "../../core/utils"
import {
  ProductAvailabilitySource,
  Availability
} from "./product-availability-source"
import { DynamicPrices } from "./store-types"

export interface StoreContext {
  availabilitySource: ProductAvailabilitySource | null
  availability: Record<string, Availability>
  prices: Record<string, DynamicPrices>
  checkoutUrl?: string
  itemCount: number
  setNextCartUpdateTime: (minutes: number) => void
  addVariantToCart: (
    ids: string[],
    variantDetails?: CommerceCartProduct[],
    category?: string
  ) => Promise<void>
  updateLocation: (location: Location) => void
}

export const StoreContext = createContext<StoreContext>({
  availabilitySource: null,
  availability: {},
  itemCount: 0,
  addVariantToCart: () => Promise.resolve(),
  prices: {},
  setNextCartUpdateTime: noop,
  updateLocation: noop
})
