import { FirstItems } from "../types/utils"
import {
  defaultWordsPerMinute,
  defaultTruncationLength,
  minReadingTime
} from "./constants"

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop: () => void = () => {}

export const identity = <T>(value: T): T => value

// Pseudorandom Number Generator (PRNG)
// LCG (aka Lehmer/Park-Miller RNG or MCG)
// https://stackoverflow.com/a/47593316
export const prng = (seed: number) => (): number =>
  ((2 ** 31 - 1) & (seed = Math.imul(48271, seed))) / 2 ** 31

// https://stackoverflow.com/a/12646864
// https://github.com/yixizhang/seed-shuffle
export function shuffle<T>(array: T[], seed = 1): T[] {
  const random = prng(seed)

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1))

    ;[array[i], array[j]] = [array[j], array[i]]
  }

  return array
}

export function shuffleAndSlice<T>(array: T[], limit: number, seed = 1): T[] {
  return shuffle(array, seed).slice(0, limit)
}

export const firstItems = <T>(items: T[], count: number): FirstItems<T> => ({
  first: items.slice(0, count),
  rest: items.slice(count)
})

export const clamp = (
  value: number,
  minimum: number,
  maximum: number
): number => Math.min(maximum, Math.max(minimum, value))

export const readTime = (
  text = "",
  wordsPerMinute = defaultWordsPerMinute
): number => {
  const trimmed = (text || "").trim()

  if (trimmed === "") return minReadingTime

  const words = trimmed.split(/\s+/)

  const readTime = Math.round(words.length / wordsPerMinute)

  return Math.max(readTime, minReadingTime)
}

export interface SearchParams {
  [key: string]: string
}

export const getSearchParams = (locationSearch: string): SearchParams => {
  return locationSearch
    .substring(1)
    .split("&")
    .reduce<SearchParams>((accumulator, token) => {
      const [key, value] = token.split("=")
      const parsedValue = ["true", "false"].includes(value)
        ? JSON.parse(value)
        : value

      if (parsedValue !== undefined) {
        accumulator[key] = parsedValue
      }

      return accumulator
    }, {})
}

export const castArray = (source: string | string[]): string[] =>
  Array.isArray(source) ? source : [source]

export const truncate = (
  text: string,
  length = defaultTruncationLength
): string => text.replace(/\s+/g, " ").substr(0, length)

export const dispatchWindowEvent = (name: string): void => {
  if (typeof window !== "undefined") {
    let event

    if (typeof Event === "function") {
      event = new Event(name)
    } else {
      event = document.createEvent("Event")
      event.initEvent(name, false, false)
    }

    window.dispatchEvent(event)
  }
}

export const shopifyImage = (url: string, width = 400): string => {
  const char = url.includes("?") ? "&" : "?"

  return `${url}${char}width=${width}`
}

export const formatTime = (duration?: number | null): string => {
  if (!duration) {
    return ""
  }

  const minutes = Math.floor(duration / 60)
  const seconds = Math.round(duration - minutes * 60)

  return `${minutes}:${seconds.toString().padStart(2, "0")}`
}

const singleOrPlural = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: any[] | any | undefined | null,
  single: string,
  plural: string
): string => {
  if (!Array.isArray(list)) {
    return ""
  }

  return list.length === 1 ? `1 ${single}` : `${list.length} ${plural}`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const itemCount = (list: any[] | any | undefined | null): string => {
  return singleOrPlural(list, "item", "items")
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const messageCount = (list: any[] | any | undefined | null): string => {
  return singleOrPlural(list, "message", "messages")
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const episodeCount = (list: any[] | any | undefined | null): string => {
  return singleOrPlural(list, "episode", "episodes")
}
