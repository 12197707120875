import { GraphQLClient } from "graphql-request"

const domain = process.env.GATSBY_STOREFRONT_DOMAIN
const storefrontAccessToken = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
const storefrontEndpoint = `https://${domain}/api/2022-04/graphql.json`

export const storefrontClient: GraphQLClient = new GraphQLClient(
  storefrontEndpoint,
  {
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": storefrontAccessToken || ""
    }
  }
)
