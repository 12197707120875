import React, { createContext, ReactNode, useContext } from "react"
import { Interpreter, State, PayloadSender } from "xstate"
import { useInterpret, useService } from "@xstate/react"
import { inspect } from "@xstate/inspect"
import newCartMachine, {
  Context,
  Events
} from "../machines/newCartMachine.machine"

if (
  typeof window !== "undefined" &&
  process.env.GATSBY_ACTIVE_ENV === "development"
) {
  // inspect({
  //   iframe: false
  // })
}

type CartProviderProps = { children: ReactNode }

type ServiceType = Interpreter<
  Context,
  any,
  Events,
  { value: any; context: Context }
>

type UseServiceHookValue = [State<Context, Events>, PayloadSender<Events>]

export const CartContext = createContext<ServiceType | undefined>(undefined)

function NewCartProvider({ children }: CartProviderProps): any {
  const service = useInterpret(newCartMachine /*, { devTools: true }*/)

  return <CartContext.Provider value={service}>{children}</CartContext.Provider>
}

function useNewCart(): UseServiceHookValue {
  const context = useContext(CartContext)

  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider")
  }

  return useService(context)
}

export { NewCartProvider, useNewCart }
