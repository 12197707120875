import { CREATE_CART } from "./gql/mutations/CREATE_CART"
import { storefrontClient } from "./client"
import { CreateCartResponse } from "./types"

type RequestResponse = CreateCartResponse

export async function cartCreate(): Promise<
  CreateCartResponse["cartCreate"]["cart"]
> {
  const response = await storefrontClient.request<RequestResponse, never>(
    CREATE_CART
  )

  const {
    cartCreate: { cart, userErrors }
  } = response

  if (userErrors.length) {
    throw new Error(userErrors[0].message)
  }

  return cart
}
