import React, { FunctionComponent } from "react"
import { TopicsContext } from "./topics-context"
import { useTopicState } from "./state/topic-state"

export const TopicsProvider: FunctionComponent = ({ children }) => {
  const value = useTopicState()

  return (
    <TopicsContext.Provider value={value}>{children}</TopicsContext.Provider>
  )
}
