const { createCanonicalUrl } = require("./create-canonical-url")

exports.onRouteUpdate = ({ location }) => {
  const domElem = document.querySelector(`link[rel='canonical']`)
  const domLink = domElem.getAttribute(`href`)
  const canonicalLink = createCanonicalUrl(location.pathname)

  if (domLink !== canonicalLink) {
    domElem.setAttribute(`href`, canonicalLink)
  }
}
