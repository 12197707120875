import { shortDateStringToDate } from "@utils/date"

const query = `
query ($handle: String!) {
  productByHandle(handle: $handle) {
    handle
    publicationDate: metafield(namespace: "product_publishing", key: "publication_date") {
      key
      value
    }
    allowPreorder: metafield(namespace: "product_publishing", key: "allow_preorder") {
      key
      value
    }
    variants(first: 1) {
      edges {
        node {
          title
          sku
          quantityAvailable
          availableForSale
          currentlyNotInStock
          requiresShipping
          price
          compareAtPrice
        }
      }
    }
  }
}
`

export interface AvailabilityResponsePayload {
  quantityAvailable: number
  availableForSale: boolean
  currentlyNotInStock: boolean
  requiresShipping: boolean
  allowPreorder: boolean
  publicationDate?: number
  price: string
  compareAtPrice: string
}

const defaultHeaders = {
  accept: "application/json",
  "accept-language": "*",
  "content-type": "application/json",
  "sec-fetch-dest": "empty",
  "sec-fetch-mode": "cors",
  "sec-fetch-site": "cross-site",
  "x-sdk-variant": "javascript",
  "x-sdk-version": "2.11.0"
}

export enum Availability {
  available, // User CAN purchase; in stock or digital
  preorder, // User CAN purchase; unreleased but preorders allowed
  notAvailableYet, // User CANNOT purchase; unreleased & preorders not allowed
  backorder, // User CAN purchase; out of stock but backorderable
  outOfStock, // User CANNOT purchase; out of stock & not backorderable
  unknown, // User CANNOT purchase; availability is being loaded/checked
  error
}

export interface AvailabilityAndPrice {
  availability: Availability
  price: string
  compareAtPrice: string
}

export const CheckingMessageId = "store-availability-checking"
export const ComingSoonMessageId = "store-availability-coming-soon"
export const PreorderMessageId = "store-add-to-cart-preorder"
export const AddToCartMessgeId = "store-add-to-cart"
export const BackorderMessageId = AddToCartMessgeId
export const OutOfStockMessageId = "store-availability-out-of-stock"
export const ErrorMessageId = "store-availability-error"
export const AddMessageId = "store-add"

export const AvailabilityMessageMap: Record<Availability, string> = {
  [Availability.unknown]: CheckingMessageId,
  [Availability.notAvailableYet]: ComingSoonMessageId,
  [Availability.preorder]: PreorderMessageId,
  [Availability.available]: AddToCartMessgeId,
  [Availability.backorder]: BackorderMessageId,
  [Availability.outOfStock]: OutOfStockMessageId,
  [Availability.error]: ErrorMessageId
}

export function calculateAvailability({
  requiresShipping,
  availableForSale,
  quantityAvailable,
  publicationDate,
  allowPreorder
}: AvailabilityResponsePayload): Availability {
  // A product is released when the publicationDate metafield is populated with a past date
  const isReleased = !publicationDate || publicationDate <= Date.now()

  if (!isReleased) {
    return allowPreorder ? Availability.preorder : Availability.notAvailableYet
  }

  if (quantityAvailable > 0 || !requiresShipping) return Availability.available

  // availableForSale is a calculated value returned by Shopify that takes inventory
  // policy into account. If it's still true at this point, that means "Continue selling
  // when out of stock" is checked in Shopify and the product is available for backorder
  if (availableForSale) return Availability.backorder

  return Availability.outOfStock
}

export interface ProductAvailabilitySourceOptions {
  storefrontDomain: string
  storefrontAccessToken: string
  onFetchStart?: (handle: string) => void
  onFetchSuccess?: (handle: string, availability: AvailabilityAndPrice) => void
  onFetchFailure?: (handle: string, err: Error) => void
}

export class ProductAvailabilitySource {
  private readonly fetchFunction: typeof window.fetch
  options: ProductAvailabilitySourceOptions

  constructor(
    options: ProductAvailabilitySourceOptions,
    fetchFunction: typeof fetch
  ) {
    this.options = { ...options }
    this.fetchFunction = fetchFunction
  }

  private async getProductData(
    handle: string
  ): Promise<AvailabilityResponsePayload> {
    const res = await this.fetchFunction(
      `https://${this.options.storefrontDomain}/api/2020-07/graphql`,
      {
        headers: {
          ...defaultHeaders,
          "x-shopify-storefront-access-token":
            this.options.storefrontAccessToken
        },
        body: JSON.stringify({ query, variables: { handle } }),
        method: "POST",
        mode: "cors",
        credentials: "omit"
      }
    )

    if (!res.ok) {
      throw new Error("Could not fetch product")
    }

    const parsed = await res.json()

    if (parsed.data.productByHandle?.variants?.edges?.length < 1) {
      throw new Error(`No variants for product "${handle}"`)
    }

    const product = parsed.data.productByHandle
    const variant = product.variants.edges[0].node

    const publicationDate = shortDateStringToDate(
      product.publicationDate?.value || ""
    )

    return {
      availableForSale: variant.availableForSale,
      currentlyNotInStock: variant.currentlyNotInStock,
      quantityAvailable: variant.quantityAvailable,
      requiresShipping: variant.requiresShipping,
      allowPreorder: product.allowPreorder && product.allowPreorder.value,
      price: variant.price,
      compareAtPrice: variant.compareAtPrice,
      publicationDate: publicationDate?.getTime?.()
    }
  }

  async getAvailability(variant: { handle: string }): Promise<Availability> {
    const handle = variant.handle

    if (!handle) {
      return Availability.unknown
    }

    this.options.onFetchStart?.(handle)

    try {
      const data = await this.getProductData(handle)
      const availability = calculateAvailability(data)
      const result: AvailabilityAndPrice = {
        availability,
        price: data.price,
        compareAtPrice: data.compareAtPrice
      }

      this.options.onFetchSuccess?.(handle, result)

      return availability
    } catch (err) {
      this.options.onFetchFailure?.(handle, err)

      return Availability.error
    }
  }
}
