const { inject } = require("@vercel/analytics")

exports.wrapRootElement = require("./src/components/app/app").default

exports.onClientEntry = () => {
  inject()
}

exports.onPreRouteUpdate = require("./gatsby/onPreRouteUpdate").default
exports.shouldUpdateScroll = require("./gatsby/shouldUpdateScroll").default
