import React, { FunctionComponent, ReactNode } from "react"
import { Toaster } from "react-hot-toast"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { enableES5 } from "immer"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { RecentlyViewedProvider } from "../../contexts/recentlyViewedContext"
import { TopicsProvider } from "../../features/topics/topics-provider"
import { ScripturesProvider } from "../../features/scriptures/scriptures-provider"
import { StoreProvider } from "../../features/store/store-provider"
import { useIE } from "../../hooks/use-ie"
import { msalConfig } from "@utils/authConfig"
import { NewCartProvider } from "@contexts/newCartContext"
import "./app.scss"
import { AlertBar } from "@components/AlertBar"
import { FeatureFlagProvider } from "@features/feature-flag-provider"
import { CustomNavigationClient } from "@utils/NavigationClient"
import { AuthProvider } from "@contexts/authContext" // TODO: remove

enableES5()

const navigationClient = new CustomNavigationClient()
const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.setNavigationClient(navigationClient)

interface AppComponentProps {
  children: React.ReactNode
  disableAuth?: boolean
}
const queryClient = new QueryClient()

export const App: FunctionComponent<AppComponentProps> = ({ children }) => {
  useIE()

  return (
    <>
      <RecentlyViewedProvider>
        <NewCartProvider>
          <MsalProvider instance={msalInstance}>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <StoreProvider>
                  <TopicsProvider>
                    <ScripturesProvider>
                      <FeatureFlagProvider>
                        <AlertBar />
                        {children}
                      </FeatureFlagProvider>
                    </ScripturesProvider>
                  </TopicsProvider>
                </StoreProvider>
                {process.env.GATSBY_ACTIVE_ENV === "development" ? (
                  <ReactQueryDevtools initialIsOpen={false} />
                ) : null}
              </QueryClientProvider>
            </AuthProvider>
          </MsalProvider>
        </NewCartProvider>
      </RecentlyViewedProvider>
      <Toaster reverseOrder={true} />
    </>
  )
}

export interface AppWrapperProps {
  element: ReactNode
}

export const AppWrapper: FunctionComponent<AppWrapperProps> = ({ element }) => (
  <App>{element}</App>
)

export default AppWrapper
