import { gql } from "graphql-request"
import { LINE_ITEM_FRAGMENT } from "../fragments/LINE_ITEM_FRAGMENT"

export const GET_CART = gql`
  query getCart($id: ID!) {
    cart(id: $id) {
      checkoutUrl
      id
      ...LineItemFragment
    }
  }
  ${LINE_ITEM_FRAGMENT}
`
