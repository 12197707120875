import { TopicList, TopicMap } from "../topic-types"

export const SET_TOPIC_LISTS = Symbol("SET_TOPIC_LISTS")
export const SET_TOPIC_MAP = Symbol("SET_TOPIC_MAP")

export interface SetTopicListsAction {
  payload: TopicList[]
  type: typeof SET_TOPIC_LISTS
}

export interface SetTopicMapAction {
  payload: TopicMap
  type: typeof SET_TOPIC_MAP
}

export type TopicActions = SetTopicListsAction | SetTopicMapAction

export const setTopicListsAction = (
  payload: TopicList[]
): SetTopicListsAction => ({
  payload,
  type: SET_TOPIC_LISTS
})

export const setTopicMapAction = (payload: TopicMap): SetTopicMapAction => ({
  payload,
  type: SET_TOPIC_MAP
})
