import { ADD_LINE_ITEM } from "./gql/mutations/ADD_LINE_ITEM"
import { storefrontClient } from "./client"
import { AddLineItemResponse, LineItemInput } from "./types"

type Lines = LineItemInput | LineItemInput[]

type RequestVariables = {
  cartId: string
  lines: Lines
}

type RequestResponse = AddLineItemResponse

export async function addLineItem(
  cartId: string,
  lines: Lines
): Promise<AddLineItemResponse> {
  if (!cartId) {
    throw new Error("cart ID is required")
  }

  const response = await storefrontClient.request<
    RequestResponse,
    RequestVariables
  >(ADD_LINE_ITEM, { cartId, lines })

  return response
}
