import type { GatsbyBrowser } from "gatsby"

const parseLocation = (routerProps: any) => {
  if (!routerProps || !routerProps.location) {
    return [null, {}]
  }

  const location = routerProps.location
  const pathname = location.pathname
  const search = location.search
    .replace("?", "")
    .split("&")
    .reduce((accumulator: any, token: any) => {
      const [key, value] = token.split("=")

      if (key && value) {
        accumulator[key] = value
      }

      return accumulator
    }, {})

  return { pathname, search, length: Object.keys(search).length }
}

const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  prevRouterProps,
  routerProps
}) => {
  const { pathname: previousPathname, search: previousSearch } = parseLocation(
    prevRouterProps
  ) as any

  const { pathname: currentPathname, search: currentSearch } = parseLocation(
    routerProps
  ) as any

  if (
    (currentSearch?.page || previousSearch?.page) &&
    currentSearch?.page !== previousSearch?.page
  ) {
    return "search-results-wrapper"
  }

  // we're on the same page - don't scroll
  if (previousPathname === currentPathname) {
    return false
  }

  return true
}

export default shouldUpdateScroll
