import { assign, createMachine } from "xstate"
import * as msal from "@azure/msal-browser"
import type { PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "../utils/authConfig"

let msalClient: PublicClientApplication

if (typeof window !== "undefined") {
  msalClient = new msal.PublicClientApplication(msalConfig)
}

export { msalClient }

export type User = {
  name?: string
  firstName?: string
  lastName?: string
  username: string
  email: string
  sub: string
  exp: number | null
  userId?: string
}

export type AuthenticationMachineEvent =
  | {
      type: "LOGGING_IN"
    }
  | {
      type: "LOGGED_IN"
      userDetails: User
    }
  | {
      type: "LOGGED_OUT"
    }
  | {
      type: "SET_MINISTRY_PARTNER"
      isMinistryPartner: boolean
    }

export interface AuthenticationMachineContext {
  userDetails?: User | null
  isMinistryPartner?: boolean
}

const authenticationMachine = createMachine<
  AuthenticationMachineContext,
  AuthenticationMachineEvent
>(
  {
    id: "authentication",
    initial: "processing",
    on: {
      SET_MINISTRY_PARTNER: {
        actions: assign((_, event) => {
          return {
            isMinistryPartner: event.isMinistryPartner
          }
        })
      }
    },
    states: {
      processing: {
        entry: ["clearUserDetailsFromContext"],
        on: {
          LOGGED_IN: {
            target: "loggedIn",
            actions: "assignUserDetailsToContext"
          },
          LOGGED_OUT: "loggedOut"
        }
      },
      loggedIn: {
        on: {
          LOGGING_IN: {
            target: "processing"
          },
          LOGGED_OUT: {
            target: "loggedOut"
          }
        }
      },
      loggedOut: {
        entry: ["clearUserDetailsFromContext"],
        on: {
          LOGGING_IN: {
            target: "processing"
          },
          LOGGED_IN: {
            target: "loggedIn",
            actions: "assignUserDetailsToContext"
          }
        }
      }
    }
  },
  {
    actions: {
      assignUserDetailsToContext: assign((_, event) => {
        if (event.type !== "LOGGED_IN") {
          return {}
        }

        return {
          userDetails: event.userDetails
        }
      }),
      clearUserDetailsFromContext: assign((_) => {
        return {
          userDetails: null
        }
      })
    }
  }
)

export default authenticationMachine
