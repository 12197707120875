import { GET_CART } from "./gql/queries/GET_CART"
import { storefrontClient } from "./client"
import { GetCart } from "./types"

type RequestVariables = { id: string }

type RequestResponse = GetCart

export async function getCart(id: string): Promise<GetCart> {
  const response = await storefrontClient.request<
    RequestResponse,
    RequestVariables
  >(GET_CART, { id })

  return response
}
