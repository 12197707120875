import React, {
  FunctionComponent,
  useReducer,
  useEffect,
  useState
} from "react"
import ReactMarkdown from "react-markdown"
import { IntlProvider } from "react-intl"
import classNames from "classnames"
import { useQuery } from "react-query"
import rehypeRaw from "rehype-raw"
import styles from "./alert-bar.module.scss"
import { StoreInLocalStorage, StoreInSessionStorage } from "./alert-cache"
import { Link } from "@components/link/link"
import { Button } from "@components/button/button"
import contentful from "@utils/contentful"

interface AlertEntryFields {
  copy: string
  buttonText: string
  buttonLink: string
  location: string
  type: string
}

export interface Alert extends AlertEntryFields {
  id: string
}

const getAlerts = async (location: AlertBarProps["location"]) => {
  const response = await contentful.getEntries<AlertEntryFields>({
    "fields.location": location,
    content_type: "alert"
  })

  return response.items.map((item) => ({
    ...item.fields,
    id: item.sys.id
  }))
}

interface AlertBarProps {
  location: "site" | "learn"
}

export const AlertBarComponent: FunctionComponent<AlertBarProps> = ({
  location
}) => {
  const { data: alerts = [] } = useQuery([`${location}-alerts`, location], () =>
    getAlerts(location)
  )
  const [viewed, setViewed] = useState<null | Record<string, boolean>>(null)

  useEffect(() => {
    // check for stored alerts of all types
    const storedAlerts = window?.sessionStorage?.getItem("ligonierAlerts")
    const storedNotifications = window?.localStorage?.getItem(
      "ligonierNotifications"
    )

    const parsed = {
      ...(storedAlerts ? JSON.parse(storedAlerts) : {}),
      ...(storedNotifications ? JSON.parse(storedNotifications) : {})
    }

    setViewed(parsed)
  }, [])

  const [closedStates, closeAlert] = useReducer(
    (state: Record<string, boolean>, alertId: string) => ({
      ...state,
      [alertId]: true
    }),
    {}
  )

  if (
    !viewed || // this is sort of a loading state, want to make sure we have checked for viewed alerts first
    !alerts.length ||
    process.env.GATSBY_ACTIVE_ENV === "test"
  ) {
    // Since we can't do conditionals in cypress we will need to make sure these don't render
    // so content underneath can be clicked. It is possible for there to be no alerts so
    // cypress will fail when trying to find them.
    return null
  }

  return (
    <IntlProvider locale="en-US">
      <div className={styles.alertWrapper}>
        {alerts.map((alert: Alert) => {
          // if alert has already been reviewed then return null
          // this could also be done through a filter on the alerts
          // instead, but I think that would technically be another
          // full loop so this is probably "better"
          if (viewed[alert.id]) return null

          const isClosed = closedStates[alert.id]

          const handleClose = () => {
            const { type } = alert

            switch (type) {
              case "alert":
                StoreInSessionStorage(alert)
                break
              case "notification":
                StoreInLocalStorage(alert)
                break
              default:
                break
            }

            closeAlert(alert.id)
          }

          return (
            <div
              key={alert.id}
              className={classNames(styles.alertBar, {
                [styles.closed]: isClosed
              })}
            >
              <ReactMarkdown
                rehypePlugins={[rehypeRaw as any]}
                className={styles.text}
              >
                {alert.copy}
              </ReactMarkdown>
              {alert.buttonLink && (
                <Button className={styles.button}>
                  <Link to={alert.buttonLink}>{alert.buttonText}</Link>
                </Button>
              )}
              <Button
                icon="16-cross"
                className={styles.closeButton}
                data-testid={isClosed ? `Closed` : `AlertBarCloseButton`}
                onClick={handleClose}
              />
            </div>
          )
        })}
      </div>
    </IntlProvider>
  )
}
