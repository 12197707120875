import { createContext } from "react"
import { noop } from "../../core/utils"
import { Volume } from "./scripture-types"

export interface ScripturesContext {
  scriptures: Volume[]
  setScriptures: (scriptures: Volume[]) => void
}

export const scripturesContextDefaultValue: ScripturesContext = {
  scriptures: [],
  setScriptures: noop
}

export const ScripturesContext = createContext<ScripturesContext>(
  scripturesContextDefaultValue
)
