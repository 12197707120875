import React, { FunctionComponent, useState } from "react"
import {
  ScripturesContext,
  scripturesContextDefaultValue
} from "./scriptures-context"

export const ScripturesProvider: FunctionComponent = ({ children }) => {
  const [scriptures, setScriptures] = useState(
    scripturesContextDefaultValue.scriptures
  )

  const value = { scriptures, setScriptures }

  return (
    <ScripturesContext.Provider value={value}>
      {children}
    </ScripturesContext.Provider>
  )
}
