import { gql } from "graphql-request"

export const LINE_ITEM_FRAGMENT = gql`
  fragment LineItemFragment on Cart {
    lines(first: 100) {
      edges {
        node {
          attributes {
            key
            value
          }
          discountAllocations {
            discountedAmount {
              amount
            }
          }
          estimatedCost {
            subtotalAmount {
              amount
            }
            totalAmount {
              amount
            }
          }
          id
          merchandise {
            ... on ProductVariant {
              availableForSale
              compareAtPriceV2 {
                amount
              }
              id
              image {
                url
                src
              }
              priceV2 {
                amount
              }
              product {
                description
                vendor
                format: metafield(
                  namespace: "product_physical"
                  key: "format"
                ) {
                  value
                  type
                }
                handle
                id
                images(first: 1) {
                  edges {
                    node {
                      url
                    }
                  }
                }
                parentCollection: metafield(
                  namespace: "product_connection"
                  key: "parent_collection"
                ) {
                  value
                  type
                }
                productType
                title
              }
              quantityAvailable
              requiresShipping
              sku
            }
          }
          quantity
        }
      }
    }
  }
`
