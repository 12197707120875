import { useCallback, useState } from "react"
import { SegmentEventName, SegmentProps } from "../core/segment/types"
import { segmentMode } from "../core/segment/constants"

const canSendRequest = ["live", "mixed"].includes(segmentMode)
const canConsoleLog = ["debug", "mixed"].includes(segmentMode)

type IdentifyProps = {
  id: string
  firstName: string
  lastName: string
  email: string
  src?: string
}

type SegmentEvent = () => {
  triggerEvent: (
    props: Partial<SegmentProps>,
    eventName: SegmentEventName,
    force?: boolean
  ) => void
  identify: (args: IdentifyProps) => void
}

export const useSegmentEvent: SegmentEvent = () => {
  const [lastEvent, setLastEvent] = useState("")

  const triggerEvent = useCallback(
    async (
      // TODO: not sure how to properly resolve this since we removed email from audio
      // the union will fail when we try to assign email to trackEventProps
      // maybe it's possible this is too much of a "catch-all" at this point?
      props: Partial<SegmentProps>,
      eventName: SegmentEventName,
      force?: boolean
    ): Promise<void> => {
      const trackEventProps = {
        ...props
      }

      const eventData = JSON.stringify({ eventName, trackEventProps })

      if (!force && eventData === lastEvent) {
        console.log(`No Event Triggered`)

        return
      }

      setLastEvent(eventData)

      if (canSendRequest) {
        await window.analytics?.track(eventName, trackEventProps)
      }

      if (canConsoleLog) {
        console.log({ eventName, trackEventProps })
      }
    },
    [lastEvent]
  )

  const identify = useCallback(
    (args: IdentifyProps): void => {
      const eventName = "identify"
      const { src, email, firstName, lastName, id } = args

      const trackEventProps: Record<string, string> = {
        first_name: firstName,
        last_name: lastName,
        email
      }

      if (src) {
        trackEventProps.src = src
      }

      const data = { eventName, trackEventProps }
      const eventData = JSON.stringify(data)

      window.segmentEmail = email

      if (eventData === lastEvent) {
        return
      }

      setLastEvent(eventData)

      if (canSendRequest) {
        window.analytics?.identify(id, trackEventProps)
      }

      if (canConsoleLog) {
        console.log({ eventName, id, trackEventProps })
      }
    },
    [lastEvent]
  )

  return { triggerEvent, identify }
}
