import { useCallback, useReducer } from "react"
import { TopicList, TopicMap } from "../topic-types"
import { TopicReducer, topicReducer, initialTopicState } from "./topic-reducer"
import { setTopicListsAction, setTopicMapAction } from "./topic-actions"
import { UseTopicState } from "./topic-state-types"

export const useTopicState = (): UseTopicState => {
  const [state, dispatch] = useReducer<TopicReducer>(
    topicReducer,
    initialTopicState
  )

  const setTopicLists = useCallback((topicLists: TopicList[]) => {
    dispatch(setTopicListsAction(topicLists))
  }, [])

  const setTopicMap = useCallback((topicMap: TopicMap) => {
    dispatch(setTopicMapAction(topicMap))
  }, [])

  return {
    ...state,
    setTopicLists,
    setTopicMap
  }
}
