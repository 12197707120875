const Url = require("url-parse")

const rootUrl = process.env.GATSBY_ROOT_URL

exports.createCanonicalUrl = (currentPath) => {
  const currentURL = new Url(rootUrl + currentPath, true)
  const { protocol, hostname, pathname } = currentURL

  return `${protocol}//${hostname}${pathname === "/" ? "" : pathname}`
}
