import { Reducer } from "react"
import { produce } from "immer"
import { TopicActions, SET_TOPIC_LISTS, SET_TOPIC_MAP } from "./topic-actions"
import { TopicState } from "./topic-state-types"

export type TopicReducer = Reducer<TopicState, TopicActions>

export const initialTopicState: TopicState = {
  topicLists: [],
  topicMap: {}
}

export const topicReducer = produce(
  (draft: TopicState, action: TopicActions) => {
    switch (action.type) {
      case SET_TOPIC_LISTS:
        draft.topicLists = action.payload

        return

      case SET_TOPIC_MAP:
        draft.topicMap = action.payload

        return
    }
  },
  initialTopicState
)
