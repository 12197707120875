import React, { createContext, ReactNode, useContext } from "react"
import { Interpreter, State, PayloadSender } from "xstate"
import { useInterpret, useService } from "@xstate/react"
import recentlyViewedMachine, {
  Context,
  Events
} from "../machines/recentlyViewed.machine"

type RecentlyViewedProviderProps = { children: ReactNode }

type ServiceType = Interpreter<
  Context,
  any,
  Events,
  { value: any; context: Context }
>

type UseServiceHookValue = [State<Context, Events>, PayloadSender<Events>]

export const RecentlyViewedContext = createContext<ServiceType | undefined>(
  undefined
)

function RecentlyViewedProvider({
  children
}: RecentlyViewedProviderProps): any {
  const service = useInterpret(recentlyViewedMachine)

  return (
    <RecentlyViewedContext.Provider value={service}>
      {children}
    </RecentlyViewedContext.Provider>
  )
}

function useRecentlyViewed(): UseServiceHookValue {
  const context = useContext(RecentlyViewedContext)

  if (context === undefined) {
    throw new Error(
      "useRecentlyViewed must be used within a RecentlyViewedProviderProps"
    )
  }

  return useService(context)
}

export { RecentlyViewedProvider, useRecentlyViewed }
