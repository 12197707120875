import React, { FunctionComponent, MouseEventHandler } from "react"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { externalLink } from "../../core/constants"

export interface LinkProps extends GatsbyLinkProps<never> {
  className?: string
  dataTestId?: string
  alt?: string
  onClick?: MouseEventHandler
}

export const Link: FunctionComponent<LinkProps> = ({
  children,
  to,
  dataTestId,
  onClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...props
}) => {
  const isExternal = /^http/.test(to) || /^\/store/.test(to)
  const isSpecial = /^(tel:|mailto:)/.test(to)
  const isStoreLigonierOrg =
    /^https:\/\/store\.ligonier\.org/.test(to) ||
    /^store\.ligonier\.org/.test(to) ||
    /^\/store/.test(to)

  if (isExternal) {
    const targetAttribute = isStoreLigonierOrg
      ? { target: "_self" }
      : { target: "_blank" }

    return (
      <a
        data-testid={dataTestId}
        href={to}
        {...externalLink}
        {...targetAttribute}
        {...props}
      >
        {children}
      </a>
    )
  }

  if (isSpecial) {
    return (
      <a data-testid={dataTestId} href={to} {...props}>
        {children}
      </a>
    )
  }

  if (onClick && !to) {
    return (
      <a data-testid={dataTestId} onClick={onClick} {...props}>
        {children}
      </a>
    )
  }

  const path = to

  const gatsbyLinkProps = { ...props, "data-testid": dataTestId }

  if (process.env.GATSBY_ACTIVE_ENV === "test") {
    // In Test envs we want to return an 'a' tag and not a gatsby link to prevent
    // the issue where Gatsby link components cause involuntary page rerenders which
    // break cypress
    return (
      <a {...gatsbyLinkProps} onClick={onClick} href={path}>
        {children}
      </a>
    )
  } else {
    return (
      <GatsbyLink {...gatsbyLinkProps} to={path} onClick={onClick}>
        {children}
      </GatsbyLink>
    )
  }
}
