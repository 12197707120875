export const urlDate = (dateStr: string) => {
  let date = new Date(dateStr)

  if (isNaN(Number(date))) date = new Date()

  const yyyy = date.getFullYear().toString()
  const mm = (date.getUTCMonth() + 1).toString().padStart(2, "0")
  const dd = date.getUTCDate().toString().padStart(2, "0")

  return `${yyyy}/${mm}/${dd}`
}

export const shortDateStringToDate = (shortDateStr: string) => {
  const shortDateRegex = /(\d\d\/){2}\d{4}/

  if (!shortDateRegex.test(shortDateStr)) return null

  const [mm, dd, yyyy] = shortDateStr.split("/").map(Number)

  return new Date(yyyy, mm - 1, dd)
}
