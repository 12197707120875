import { ShopifyProduct, ShopifyParentCollection } from "@src-types/shopify"

export const defaultHeaders = {
  accept: "application/json",
  "accept-language": "*",
  "content-type": "application/json",
  "sec-fetch-dest": "empty",
  "sec-fetch-mode": "cors",
  "sec-fetch-site": "cross-site",
  "x-sdk-variant": "javascript",
  "x-sdk-version": "2.11.0"
}

interface QueryVariables {
  [key: string]: string | number | boolean
}

export const runQuery = async (query: string, variables?: QueryVariables) => {
  try {
    const res = await fetch(
      `https://${process.env.GATSBY_STOREFRONT_DOMAIN!}/api/2023-07/graphql`,
      {
        headers: {
          ...defaultHeaders,
          "x-shopify-storefront-access-token":
            process.env.GATSBY_STOREFRONT_ACCESS_TOKEN!
        },
        body: JSON.stringify({
          query,
          variables
        }),
        method: "POST",
        mode: "cors",
        credentials: "omit"
      }
    )

    const json = await res.json()

    return json
  } catch (e) {
    return null
  }
}

export const productByHandleQuery = `
query ($handle: String!) {
  productByHandle(handle: $handle) {
    handle
    title
    motivationCode: metafield(namespace: "product_definition", key: "motivation_code") {
      key
      value
    }
    packageName: metafield(namespace: "product_sales", key: "package_name"){
      key
      value
    }
    packageVariant: metafield(namespace: "product_sales", key: "package_variant"){
      key
      value
    }
    packageVariantQuantity: metafield(namespace: "product_sales", key: "package_variant_quantity"){
      key
      value
    }
    packageVariantPrice: metafield(namespace: "product_sales", key: "package_variant_price"){
      key
      value
    }
    format: metafield(namespace: "product_physical", key: "format") {
      key
      value
    }
    color: metafield(namespace: "product_physical", key: "color") {
      key
      value
    }
    suppressCoverDownload: metafield(namespace: "product_media", key: "suppress_cover_download") {
      key
      value
    }
    language: metafield(namespace: "product_content", key: "language") {
      key
      value
    }
    publicationDate: metafield(namespace: "product_publishing", key: "publication_date") {
      key
      value
    }
    allowPreorder: metafield(namespace: "product_publishing", key: "allow_preorder") {
      key
      value
    }
    disableDropShadow: metafield(namespace: "product_setting", key: "disable_drop_shadow") {
      key
      value
    }
    backorderCustomDate: metafield(namespace: "product_backorder", key: "custom_date") {
      key
      value
    }
    backorderCustomMessage: metafield(namespace: "product_backorder", key: "custom_message") {
      key
      value
    }
    collections(first: 1) {
      edges {
        node {
          title
        }
      }
    }
    parentCollection: metafield(namespace: "product_connection", key: "parent_collection") {
      key
      value
    }
    variants(first: 1) {
      edges {
        node {
          storefrontId: id
          title
          sku
          image {
            originalSrc
          }
          quantityAvailable
          availableForSale
          currentlyNotInStock
          requiresShipping
          price { amount }
          compareAtPrice { amount }
        }
      }
    }
  }
}
`
export const donationByHandleQuery = `
query ($handle: String!) {
  productByHandle(handle: $handle) {
    handle
    title
    variants(first: 4) {
      edges {
        node {
          storefrontId: id
          title
          sku
          image {
            originalSrc
          }
          quantityAvailable
          availableForSale
          currentlyNotInStock
          requiresShipping
          price { amount }
          compareAtPrice { amount }
        }
      }
    }
  }
}
`

const collectionByHandleQuery = `
  query($collectionHandle: String!) {
    collectionByHandle(handle: $collectionHandle) {
      id
      handle
      title
      descriptionHtml
      image {
        originalSrc
      }
      meta_teacherIDs: metafield(namespace: "product_publishing", key: "authors") {
        key
        value
        type
      }
      meta_publisher: metafield(namespace: "product_publishing", key: "publisher") {
        key
        value
        type
      }
      meta_topicIDs: metafield(namespace: "product_definition", key: "topics") {
        key
        value
        type
      }
      meta_primaryTopicID: metafield(namespace: "product_definition", key: "primary_topic") {
        key
        value
        type
      }
      meta_subtitle: metafield(namespace: "product_definition", key: "subtitle") {
        key
        value
        type
      }
      meta_packaging: metafield(namespace: "product_physical", key: "packaging") {
        key
        value
        type
      }
      meta_dimensions: metafield(namespace: "product_physical", key: "dimensions") {
        key
        value
        type
      }
      meta_color: metafield(namespace: "product_physical", key: "color") {
        key
        value
        type
      }
      meta_coverType: metafield(namespace: "product_physical", key: "cover_type") {
        key
        value
        type
      }
      meta_bindingType: metafield(namespace: "product_physical", key: "binding_type") {
        key
        value
        type
      }
      meta_format: metafield(namespace: "product_physical", key: "format") {
        key
        value
        type
      }
      meta_pageCount: metafield(namespace: "product_physical", key: "page_count") {
        key
        value
        type
      }
      meta_messageCount: metafield(namespace: "product_physical", key: "message_count") {
        key
        value
        type
      }
      meta_productContent: metafield(namespace: "product_content", key: "product_content") {
        key
        value
        type
      }
      meta_language: metafield(namespace: "product_content", key: "language") {
        key
        value
        type
      }
      meta_scriptureReferenceIDs: metafield(namespace: "product_content", key: "scripture_references") {
        key
        value
        type
      }
      meta_primaryScriptureReferenceID: metafield(namespace: "product_content", key: "primary_scripture_reference") {
        key
        value
        type
      }
      meta_teachingSeriesSlug: metafield(namespace: "product_content", key: "teaching_series") {
        key
        value
        type
      }
    }
  }
`

export const donationByHandle = async (
  handle: string
): Promise<ShopifyProduct | null> => {
  if (!handle || handle === "") {
    return null
  }

  const productResponse = await runQuery(donationByHandleQuery, { handle })

  const product = productResponse?.data?.productByHandle

  if (!product) return null

  const collectionHandle = product.parentCollection?.value

  if (!collectionHandle) {
    return {
      ...product,
      parentCollection: null
    }
  }

  return {
    ...product
  }
}

export const productByHandle = async (
  handle: string
): Promise<ShopifyProduct | Record<string, never> | null> => {
  if (!handle?.length) {
    return {}
  }

  const productResponse = await runQuery(productByHandleQuery, { handle })
  const product = productResponse?.data?.productByHandle

  if (!product) return null

  const collectionHandle = product.parentCollection?.value

  if (!collectionHandle) {
    return {
      ...product,
      parentCollection: null
    }
  }

  const parentCollectionResponse = await runQuery(collectionByHandleQuery, {
    collectionHandle
  })

  const parentCollectionRaw = parentCollectionResponse?.data?.collectionByHandle

  product.teachers = parentCollectionResponse?.data?.collectionByHandle
    ?.meta_teacherIDs?.value
    ? JSON.parse(
        parentCollectionResponse?.data?.collectionByHandle?.meta_teacherIDs
          ?.value
      )
    : []

  const parentCollection = !parentCollectionRaw
    ? null
    : Object.keys(parentCollectionRaw).reduce(
        (collection: Partial<ShopifyParentCollection>, key) => {
          const field = parentCollectionRaw[key]

          if (key.startsWith("meta_")) {
            const value = ["json", "json_string"].includes(field?.type)
              ? JSON.parse(field.value)
              : field?.value ?? null

            const newKey = key.replace("meta_", "")

            collection[newKey as keyof ShopifyParentCollection] = value
          } else {
            collection[key as keyof ShopifyParentCollection] = field
          }

          return collection
        },
        {}
      )

  return {
    ...product,
    parentCollection
  }
}
