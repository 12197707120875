import React, { FunctionComponent, MouseEventHandler, useMemo } from "react"
import classNames from "classnames"
import { IconVariant } from "./icon-types"
import styles from "./icon.module.scss"

export * from "./icon-paths"
export * from "./icon-types"

export interface IconProps {
  className?: string
  disabled?: boolean
  id?: string
  onClick?: MouseEventHandler<SVGElement>
  variant: IconVariant
}

export const Icon: FunctionComponent<IconProps> = ({
  className,
  disabled,
  id,
  onClick,
  variant
}) => {
  const size = useMemo(() => variant?.match(/^\d+/)![0], [variant])

  return (
    <svg
      data-testid={id ? `icon-${id}` : `icon-${variant}`}
      width={size}
      height={size}
      id={id}
      fill="currentColor"
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        styles.icon,
        {
          [styles.disabled]: disabled,
          [styles.interactive]: Boolean(onClick)
        },
        className
      )}
      onClick={disabled ? undefined : onClick}
    >
      <use xlinkHref={`/icons/sprite.svg#${variant}`} />
    </svg>
  )
}
